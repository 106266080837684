<template>
  <div
    class="answer_ques"
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(183,208,208,0.8)"
  >
    <div class="answer_ques_title" v-if="!studentFlag">
      <p>当前可用问卷</p>
    </div>
    <el-table :data="tableDaba" style="width: 100%" :show-header="false" :cell-style="timeStyle" v-if="!studentFlag">
      <el-table-column type="index" align="center"></el-table-column>
      <el-table-column prop="title" align="left">
        <template slot-scope="scope">
          <span
            @click="goAnswer(scope.row)"
            style="display:flex;"
            v-if="scope.row.isanswer==0"
          >{{ scope.row.title}}</span>
          <span
            v-if="scope.row.isanswer ==1"
            class="isanswer"
            style="display:flex;"
            @click="err"
          >{{scope.row.title}}</span>
        </template>
      </el-table-column>
    </el-table>
    <table  class="my-table2" v-if="studentFlag">
      <thead>
        <tr>
          <th>序号</th>
          <th>问卷名称</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, index) in tableData" :key="index" @click="goAnswer(value)" v-show="value.isanswer == 0">
          <td>{{ index + 1   }}</td>
          <td>
            <span style="color: #3A70E2">《{{ value.title }}》</span>
          </td>
          <!-- <td ><button style="width: 104px;height: 31px;line-height:31px;border-radius: 10px;background: #FFFFFF;color:#0256FF;font-size:16px;font-weight: 500;text-align: center;border:0px;" @click.stop="goAnswer(value)">开始测评</button></td> -->
        </tr>
        <tr v-for="(value, index) in tableData" :key="index" @click="err" v-show="value.isanswer == 1">
          <td>{{ index + 1   }}</td>
          <td>
            <span style="color: #3A70E2">《{{ value.title }}》</span>
          </td>
          <!-- <td ><button style="width: 104px;height: 31px;line-height:31px;border-radius: 10px;background: #FFFFFF;color:#0256FF;font-size:16px;font-weight: 500;text-align: center;border:0px;" @click.stop="err(value)">开始测评</button></td> -->
        </tr>
      </tbody>
    </table>

  </div>
</template>
<script>
import { getQueuser } from "@/api/answer.js";
export default {
  data() {
    return {
      loading: true,
      quesList: [],
      tableDaba: [],
      studentFlag:false,
    };
  },
  created() {
    let student = localStorage.getItem("student");
    if(student==1){
      this.studentFlag=true;
    }
    this.loadquesList();
  },
  methods: {
    // 数据加载
    loadquesList() {
      getQueuser().then(res => {
        this.tableDaba = res.data;
        this.loading = false;
      });
    },
    // 表格样式
    timeStyle(row) {
      return "background:rgb(226, 239, 240);color:#262626;border-bottom: 1px solid #707070";
    },
    // 跳转问卷填写
    goAnswer(row) {
      this.$router.push(`/answer_content/ques_content?qsid=${row.qsid}`);
    },
    // 错误触发
    err() {
      this.$message({
        showClose: true,
        duration: 1000,
        message: "该问卷已被使用",
        type: "warning"
      });
    }
  }
};
</script>
<style lang="less">
.answer_ques {
  width: 90%;
  margin: 10px auto;
  border-radius: 15px;
  box-shadow: rgb(124, 124, 124) 4px 2px 15px;
  .answer_ques_title {
    border-radius: 15px 15px 0px 0px;
    background: rgb(220, 239, 241);
    p {
      text-align: center;
      padding: 10px 0;
      font-size: 20px;
      font-weight: 700;
      background: linear-gradient(to right, rgb(0, 136, 125), rgb(5, 201, 184));
      -webkit-background-clip: text;
      color: transparent;
      text-shadow: white 1px 3px 9px;
    }
  }
  .el-table {
    border-radius: 0px 0px 15px 15px;
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    color: black !important;
    background-color: rgb(139, 179, 177) !important;
    font-weight: 700;
    transition: all 0.25s;
  }

  // 加载等待框
  .el-loading-mask {
    .el-loading-spinner {
      .el-icon-loading {
        color: rgb(34, 77, 85);
      }
      .el-loading-text {
        color: rgb(34, 77, 85);
        font-weight: 700;
      }
    }
  }
  .isanswer {
    text-decoration: line-through;
    color: #aaaaaa;
  }
}
// 儿童版
.my-table2{
  width: 100%;
  border-collapse: collapse;
  thead tr {
    height: 56px;
    background: rgba(242, 245, 250, 0.75);
    border-radius: 9px;
    th {
      color: #687182;
      text-align: center;
      font-size: 24px;
    }
  }
  tbody {
    tr {
      height: 68px;
      td {
        text-align: center;
        font-size: 20px;
      }
      &:hover {
        // background: #D1F3F4;
        // border-bottom: 2px solid #51D2D7;
        background: #44D1D8;
      }
    }
  }
}
</style>